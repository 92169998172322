import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/Blogs.css'
import '../components/Styles/about.css'
import blog3 from '../assets/images/blg1.png';
import Blog5 from './Artboard.jpg';
import Blog6 from './blog6.png';
import Bg9 from './bg9.png';
import Blog4 from '../components/Styles/Blog4.png';
import feature from './feature.png';
import bg3 from './bg3.png';
import bl8 from './bl8.png';
import b10a from './bg10.png';
import b11 from './blog11-min.png';
import b12 from './bg12-min.png';
import b13 from './bg13-min.png';
import b15 from './blg15-min.png';
import b16 from './Blg16.png';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Blog = () => {
    useEffect(()=>{
        AOS.init();
       
      })
    
  return (
    <div>
         <Helmet>
      
      <title>Explore the 33 Percent Blog - Expert Analysis & Tips</title>
      <meta name="description" content="Discover valuable insights and expert tips on the 33 percent Blog. Stay informed with in-depth analysis and boost your knowledge. Explore now!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
         
        <section className='about__us_main' >
          
          <h1 data-aos="zoom-in">Blog</h1>
         </section>

<br /><br /><br />
      <section className='blog_sec'>
      <div className='blog_row'>

      <div  className='blog_col'>
        
        <a href="The Importance of Value Investing"><img src={b16} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="The-Importance-of-Value-Investing"> The Importance of Value Investing: Staying Focused on Graham's Principles</a></h2>

        </div>

      <div  className='blog_col'>
        
        <a href="A-Key-Strategy-in-Today's-Shariah-Compliant Market"><img src={b15} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="A-Key-Strategy-in-Today's-Shariah-Compliant Market">Diversification: A Key Strategy in Today's Shariah-Compliant Market</a></h2>

        </div>
      <div  className='blog_col'>
        
        <a href="Navigatin-the-Winds-of-Change"><img src={Blog6} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Navigatin-the-Winds-of-Change">Navigating the Winds of Change: Strategic Insights for Value Investors</a></h2>

        </div>

      <div  className='blog_col'>
        
        <a href="Navigating-Market-Volatility"><img src={b13} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Navigating-Market-Volatility">Navigating Market Volatility: The Enduring Wisdom of Value Investing</a></h2>

        </div>


 

 
   
 
       
       
      </div>
      <br />
      
      <div className='blog_row'>
      <div  className='blog_col'>
        
        <a href="Investing-in-Tomorrow"><img src={b12} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Investing-in-Tomorrow">Investing in Tomorrow: The AI Revolution and Long-Term Growth Opportunities</a></h2>

        </div>

      <div  className='blog_col'>
        
        <a href="Mastering-Market-Volatility"><img src={b11} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Mastering-Market-Volatility">Mastering Market Volatility: A Strategic Guide to Diversification and Investment Resilience</a></h2>

        </div>
 
      <div  className='blog_col'>
        
        <a href="Bitcoin-Soars-to-New-Heights"><img src={b10a} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Bitcoin-Soars-to-New-Heights">Bitcoin Soars to New Heights:  Navigating New Horizons and Nvidia's Performance within the 33% Fund Holdings</a></h2>

        </div>

      <div className='blog_col'  >
        <a href="Navigating-Market-Dynamics"><img src={Bg9} alt="blog1_pic" /></a>
        <h4>Business & Finance</h4>
          <h2><a href="Navigating-Market-Dynamics"> Navigating-Market-Dynamics: The Importance of Diversification in Today's Financial Landscape</a></h2>

        </div>
      

 

    
       
      </div>
      <br />
   <div className='blog_row'>
   <div className='blog_col'>

<a href="Navigating-Prosperity"><img src={bl8} alt="blog1_pic" /></a>
  <h4>Business & Finance</h4>
  <h2><a href="Navigating-Prosperity">Navigating Prosperity: The Crucial Role of Diversification in a Surging U.S. Stock Market</a><span style={{visibility:"hidden"}}>dadsssssasd ssssss sss</span></h2>

</div>


   <div className='blog_col' >
        <a href="today's-stock-market-analysis"><img src={bg3} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="today's-stock-market-analysis">Navigating the Ebb and Flow: A Positive Outlook on Today's Stock Market Dynamics</a><span style={{visibility:"hidden"}}>dadsssssasd ssss</span></h2>

        </div>
 
   <div className='blog_col'>
        <a href="January-2024-high-tech-success-insights"><img src={Blog6} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="January-2024-high-tech-success-insights">Navigating the Highs and Lows: A January 2024 Outlook for High-Tech Companies</a><span style={{visibility:"hidden"}}>dadsssssasd ssss</span></h2>

        </div>
   
   <div className='blog_col'>
        <a href="A-Strategic-Shift-Towards-Accumulation-Mode"><img src={Blog5} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="A-Strategic-Shift-Towards-Accumulation-Mode">2024's Stock Market Outlook: A Strategic Shift Towards Accumulation Mode<span style={{visibility:"hidden"}}>dadsssssasdf ssss</span></a></h2>

        </div>
      

 
 
      </div>

  <br />  
      <div className='blog_row'>
      <div className='blog_col'>
        <a href="Mastering_Investment-Strategies-and-Styles"><img src={blog3} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Mastering-Investment-Strategies-and-Styles">Mastering Investment Strategies and Styles  <span style={{visibility:"hidden"}}><span style={{visibility:"hidden"}}> sssssdf sss sssssdf sssssdf</span></span></a></h2>

        </div>
  
      <div className='blog_col'>
        <a href="Navigatingthe-Market-Transition-From-Bear-to-Bull"><img src={feature} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Navigatingthe-Market-Transition-From-Bear-to-Bull">Navigating the Market Transition: From Bear to Bull</a> <span style={{visibility:"hidden"}}> sssssdf sss sssssdf sssssdf</span></h2>

        </div>
 





      <div  className='blog_col'>
        <a href="Navigating-the-Uncertainties"><img src={bg3} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Navigating-the-Uncertainties">Navigating the Uncertainties: A Look into the First Trading Day of 2024 <span style={{visibility:"hidden"}}></span></a></h2>

        </div>
 
        <div className='blog_col'>
        <a href="Long-term-Growth-Strategies"><img src={Blog4} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Long-term-Growth-Strategies">Long term Growth Strategies</a><span style={{visibility:"hidden"}}> sssssdf sss sssssdf sssssdf sssssssssssssdf  sssss</span> </h2>

        </div>


      </div>
    
      </section>
    </div>
  )
}

export default Blog;