import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/Contact.css'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Contact = () => {
    useEffect(()=>{
        AOS.init();
       
      })
      
    
  return (
    <div>
                 <Helmet>
       <title>Contact - Get in Touch with Our Team Today for Investment Opportunities - 33 Percent Fund</title>
      <meta name="description" content="Contact 33% Fund today to explore exciting investment opportunities! Reach out to our experienced team and start your journey towards financial success. Get in touch now!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
       
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">Contact Us</h1>
         </section>
          <br /><br />
       <section className='con_center'>
       <h2 data-aos="fade-right">Get in Touch</h2>
       <p data-aos="fade-right">We're All Ears Reach out to us at  <a href="mailto:info@33percentfund.com" >&nbsp;&nbsp;info@33percentfund.com</a>  or visit us at 24285 Katy Freeway, Suite 300
Katy TX 77494. We're here to answer your questions, provide guidance, and welcome you into the world of successful investing.</p>
       <br />

       <div className='mb'>
       
  <label htmlFor="" data-aos="fade-right">First Name</label>
<br />
<input type="text" />
<br /><br />
<label data-aos="fade-right" htmlFor="">Last Name</label>
<br />
<input type="text" />
<br /><br />
<label htmlFor="" data-aos="fade-right">Email</label>
<br />
<input type="text" />
<br /><br />
<label htmlFor="" data-aos="fade-right">Phone Number</label>
<br />
<input type="text" />

<br /><br />
<label htmlFor="" data-aos="fade-right">Message</label>
<br />
<input type="text" />
<br /><br />
<button data-aos="fade-right">Send Message</button>
<br /><br />
</div>

       <div className='con_row'>
     <div className='con_col'>
     <h2 data-aos="fade-right">Contact Information</h2>
     <p data-aos="fade-right"> Say something to start a live chat!</p>
     <br />
    <div className='con_rowico'>
     <div className='can_colico'>
        <i class="ri-phone-fill"></i>
      </div>
      
      <div className='can_col2ico'>
      
      <a href="tel:346 387 7187" data-aos="fade-right">346 387 7187</a>
      </div>
    </div>
    <br />
    <div className='con_rowico'>
     <div className='can_colico'>
     <i class="ri-mail-line"></i>   
      </div>
      <div className='can_col2ico'>
      <a href="mailto:info@33percentfund.com" data-aos="fade-right">&nbsp;&nbsp;info@33percentfund.com</a>
      </div>
    </div>
    <br />
    <div className='con_rowico'>
     <div className='can_colico'>
     <i class="ri-map-pin-2-fill"></i>
      </div>
      
      <div className='can_col2ico'>
     <p data-aos="fade-right">24285 Katy Freeway, Suite 300 Katy TX 77494</p>
      </div>
    </div>
    </div>

<div className='con_col2'>
<div className='rowinp'>
<div className='colinp'>
<label htmlFor="" data-aos="fade-right">First Name</label>
<br />
<input type="text" />
</div>
<div className='colinp'>
<label data-aos="fade-right" htmlFor="">Last Name</label>
<br />
<input type="text" />
</div>
</div>
<br />
<div className='rowinp'>
<div className='colinp'>
<label htmlFor="" data-aos="fade-right">Email</label>
<br />
<input type="text" />
</div>
<div className='colinp'>
<label htmlFor="" data-aos="fade-right">Phone Number</label>
<br />
<input type="text" />
</div>
</div>
<br />
<label htmlFor="" data-aos="fade-right">Message</label>
<br />
<input type="text" />
<br /><br />
<button data-aos="fade-right">Send Message</button>

</div>

    </div>

       </section>

       <br /><br />
       <section className='last_con'>
      <h2 data-aos="fade-right">Follow Our Journey:</h2>
      <p data-aos="fade-right">Stay connected with us on LinkedIn, Facebook, and Twitter to receive updates, market insights, and news related to our investment strategies and financial landscape.</p>
      <h2 data-aos="fade-right">Legal and Compliance:</h2>
       </section>
    


<br /><br />
    
    <section className='last_con'>
      <div className='row__conlst'>
        <div className='can_conlst'>
          <h2 data-aos="fade-right">Privacy Notice: Your <br />Privacy, Our Priority </h2>
          <p data-aos="fade-right">Learn how we collect, use, and protect your data with our comprehensive privacy notice. Your privacy is paramount to us, and this notice outlines our commitment to safeguarding your information.</p>
        </div>
        <div className='can_conlst2'>
          <h2 data-aos="fade-right">Terms & Conditions: Navigating Our Digital Space </h2>
          <p data-aos="fade-right">Review our terms and conditions to understand the rules and guidelines for using our website. It outlines the terms of engagement and usage that govern your interaction with our platform.</p>
        </div>
        <div className='can_conlst3'>
    <h2 data-aos="fade-right">Regulatory Disclosures: Compliance and Transparency </h2>
    <p data-aos="fade-right">Find essential compliance-related information here, ensuring you have the insights you need about regulations that impact our financial industry</p>
</div>
      </div>
    </section>
        
    </div>
  )
}

export default Contact;