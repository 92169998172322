import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/inv.css'
import dm1 from '../assets/images/inv1.png'
import dm2 from '../assets/images/inv2.png'
import dm3 from '../assets/images/inv3.png'
import dm4 from '../assets/images/inv4.png'
import dm5 from '../assets/images/inv5.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Investment = () => {
    useEffect(()=>{
        AOS.init();
       
      })
    
  return (
    <div>
         <Helmet>
       <title>Investment Strategies for Financial Success - 33 Percent Fund</title>
      <meta name="description" content="Discover Winning Investment Strategies for Financial Success | Your Path to Wealth Begins Here! Explore Proven Investment Strategies to Maximize Returns and minimize Risks."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">Investment Strategies</h1>
         </section>

       <section className='invest_center'>
      <div className='row_inv'>
        <div className='col_inv'>
            <img data-aos="zoom-in" src={dm1} alt="img" />
            <br /><br />
            <h2 data-aos="fade-right">Dividend-Oriented Strategy:</h2>
            <p data-aos="fade-right">Experience the power of consistent income and potential growth with our dividend-oriented strategy. Designed to provide stability while capturing market opportunities, this strategy aligns with your long-term financial goals.</p>
            <hr  data-aos="fade-right"/>
        </div>
        <div className='col_inv'>
        <img src={dm2} alt="img" data-aos="zoom-in"/>
            <br /><br />
            <h2 data-aos="fade-right">Growth Investing Strategy:</h2>
            <p data-aos="fade-right">Explore our growth investing strategy that focuses on companies with high growth potential. Ideal for those seeking to capitalize on innovative trends and emerging markets, this strategy aims to deliver substantial long-term returns.</p>
            <hr data-aos="fade-right" />
        </div>
        <div className='col_inv'>
        <img src={dm3} alt="img" data-aos="zoom-in"/>
            <br /><br />
            <h2 data-aos="fade-right">Asset Allocation Strategy:</h2>
            <p data-aos="fade-right">Discover our asset allocation strategy, a comprehensive approach to managing risk and generating steady returns. By strategically distributing your investments across various asset classes, we aim to optimize your long-term financial outcomes.</p>
            <hr data-aos="fade-right"  />
        </div>
      </div>
      <div className='row_inv'>
        <div className='col_inv'>
        <br /><br />
            <img src={dm4} alt="img" data-aos="zoom-in"/>
            <br /><br /><br />
            <h2 data-aos="fade-right">Value Investing Strategy:</h2>
            <p data-aos="fade-right">Delve into our value investing approach, where we uncover undervalued stocks with the potential to yield significant returns. This strategy combines meticulous analysis and patience to capitalize on market inefficiencies.</p>
            <hr  data-aos="fade-right"/>
        </div>
        <br />
        <div className='col_inv'>
        <br /><br />
        <img  src={dm5} alt="img" data-aos="zoom-in"/>
            <br />
            <h2 data-aos="fade-right">Special Situations Strategy: </h2>

            <p data-aos="fade-right">Dive into our special situations strategy, designed to identify opportunities arising from transformative events like mergers, acquisitions, and spin-offs. By capitalizing on price dislocations, this strategy aims to enhance your portfolio's performance.</p>
            <hr  data-aos="fade-right"/>
        </div>
        <div className='col_inv' style={{visibility:"hidden"}}>
        <img src={dm5} alt="img" />
            <br /><br />
            <h2>Special Situations Strategy:
Navigating Transformative Events for Profit</h2>
            <p>Dive into our special situations strategy, designed to identify opportunities arising from transformative events like mergers, acquisitions, and spin-offs. By capitalizing on price dislocations, this strategy aims to enhance your portfolio's performance.</p>
       
        </div>
        
      </div>
      
       </section>

    </div>
  )
}

export default Investment;