import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/ebook.css'

import AOS from 'aos';
import 'aos/dist/aos.css';
import book from '../assets/images/book.png'

const Ebook = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
            <Helmet>
       <title>Download Your Exclusive eBook | 33 Percent Fund Insights</title>
      <meta name="description" content="Unlock invaluable insights with our exclusive eBook! Download now for a 33 percent deeper understanding of fund strategies. Your key to financial success awaits"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
         
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">eBook</h1>
         </section>
         <br /><br />
         <section className='esec'>
              <div className='esec_row'>
                <div className='esec_col1'>
                    <img src={book} alt="" />
                </div>
                <div className='esec_col2'>
                    <p>Investivus is your trusted companion on your investment journey, whether you're a complete novice or an experienced investor looking for fresh insights. Our comprehensive guide takes you from the very beginning, walking you through the art of investment with step-by-step explanations and insider tips.</p>
                </div>
              </div>
         </section>
<br /><br />
 <section className='esec'>
<h2>Enter your email address below to get a free copy of “Investivus for the rest of us”:</h2>
<br />
<div className='erow'>
    <div className='ecol'>
        <label htmlFor="">FULL NAME</label>
        <br />
        <input type="text" />
    </div>
    <div className='ecol'>
    <label htmlFor="">EMAIL</label>
    <br />
        <input type="text" />
    </div>
</div>
<br />
<button ><a href="Ebook_download">Download</a> </button>
 </section>
<br />
 <section className='esec2'>
<div className='esecc'>
<h2>Get Started Today</h2>
<p>Ready to embark on your investment journey with us? Contact our investor relations team to get started or schedule a one-on-one consultation with one of our portfolio managers.</p>
</div>
 </section>
    </div>
  )
}

export default Ebook;