import React, { useEffect, useRef} from 'react'
import { NavLink } from 'react-router-dom'
import './header.css'
import './drop.css'
import logo from './logo.jpg';




 const Header =()=> {
  const headerRef = useRef(null);
  const stickyHeaderFunc = ()=>{
    window.addEventListener('scroll', () => {
      if(
        document.body.scrollTop > 80  ||
        document.documentElement.scrollTop > 80)
        {
        headerRef.current.classList.add('sticky__header')
      }
      else{
        headerRef.current.classList.remove('sticky__header')
      }
    })
  }



  useEffect(()=>{

// sidebar open close js code
let navLinks = document.querySelector(".nav-links");
let menuOpenBtn = document.querySelector(".navbar .bx-menu");
let menuCloseBtn = document.querySelector(".nav-links .bx-x");


menuOpenBtn.onclick = function() {
navLinks.style.left = "0";

}

menuCloseBtn.onclick = function() {
  navLinks.style.left = "-100%";
}

document.onclick=function(e){
  if( !navLinks.contains(e.target) && !menuOpenBtn.contains(e.target)){
    navLinks.style.left = "-100%";
      
  }
 }




// sidebar submenu open close js code

stickyHeaderFunc();
return () => window.removeEventListener("scroll", stickyHeaderFunc); 
   });


  return (
    <div >
  <nav ref={headerRef}>
    <div class="navbar">
      <i class='bx bx-menu'></i>
      <div class="logo"><NavLink to="/"><img style={{width:"80px"}} src={logo}/></NavLink></div>
      <div class="nav-links" >
        <div class="sidebar-logo">
          <span class="logo-name"></span>
          <i class='bx bx-x' style={{marginLeft:"70%",marginTop:"20%"}}></i>
        </div>
        <ul class="links">
        <li><a href="/">Home</a></li>

<li><a href="/About-Us">About Us</a></li>
<li><a href="/Investment-Strategy">Investment Strategies</a></li>
<li>
<div className="dropdown">
  <button className="dropbtn">Investor Resources </button>
  <i  style={{fontSize:"13px",}} className='bx bxs-chevron-down '  ></i>
        
  <div className="dropdown-content">
  <li><a id="aa"  href="/Fund-Information">Fund Information</a></li>
  <li><a id="aa" href="/Faq">Faq</a></li>
            
  </div>
</div>
</li>
          <li><a href="/blogs">Blogs</a></li>
        

<li><a href="/Careers">Careers</a></li>         
<li><a href="/ebook">ebook</a></li>

          <li><a href="/Contact-Us">Contact Us</a></li>
         <li style={{color:"white",background:"#003889"}}><a  style={{color:"white"}} href='https://33percentfund.cashflowportal.com/offering/d9112cb28044441c9229ee7323d373c6'>Investor Login</a></li> 

        </ul>
      </div>
          </div>
  </nav>
 

    </div>
  )
}
export default Header;

