import React, { useEffect } from 'react'
import '../components/Styles/page.css'
import mobile1 from '../assets/images/mobile1.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Page1 = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='about__us' >
          <h1 data-aos="zoom-in">Unlock Your Financial Future with 33 Percent Fund</h1>
          <p data-aos="zoom-in">Empowering Your Investments with Strategic Insights and Expertise</p>
         </section>
         <br /><br />
         <section className='page_main'>
          <h2 data-aos="fade-right">Introduction:</h2>
          <p data-aos="fade-right">Welcome to 33 Percent Fund, where we specialize in maximizing your investment’s potential. Led by our Chairman and CEO, Mohammad Ali, our team of experts is dedicated to delivering exceptional returns and unparalleled service. Whether you are a seasoned investor or just starting, we offer tailored solutions to meet your unique needs.</p>
         </section>
         <section className='page_main'>
           <div className='page_row'>
            <div className='col_page'>
             <h2 data-aos="fade-right">Have questions or need more information? Schedule a one-on-one Zoom call with our Chairman and CEO, Mohammad Ali. </h2>
             <p data-aos="fade-right">Gain insights directly from the leader of our fund and discover how 33 Percent Fund can be the catalyst for your financial growth.
Schedule a Meeting Now</p>
<br />
<button data-aos="zoom-in">Book Now</button>
            </div>
            <div className='col_page'>
                <img data-aos="zoom-in" src={mobile1} alt="" />

</div> 
           </div>
         </section>
<br />
         <section className='page_last'>
        <div className='lst_text'>
        <h2 data-aos="fade-right">Sign Up and Invest</h2>
        <p data-aos="fade-right">Ready to elevate your investment journey? Join 33 Percent Fund and gain access to exclusive investment opportunities, expert insights, and personalized strategies designed to optimize your portfolio’s performance.</p>
        <button data-aos="zoom-in">Join the Fund Now</button>
        </div>
         </section>
    </div>
  )
}

export default Page1;