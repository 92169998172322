import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import f1 from './f1.pdf';
import f2 from './f2.pdf';
import f3 from './f3.pdf';
import f4 from './f4.pdf';
import f5 from './33 Percent Fund Pioneering Strategy.pdf';
import '../components/Styles/inv.css'
import '../components/Styles/res.css'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Resources = () => {
    useEffect(()=>{
        AOS.init();
       
      })
    
  return (
    <div>
         <Helmet>
       <title>Resources - 33 Percent Fund</title>
      <meta name="description" content="Discover Winning Investment Strategies for Financial Success | Your Path to Wealth Begins Here! Explore Proven Investment Strategies to Maximize Returns and minimize Risks."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">Resources</h1>
         </section>
<br/><br/><br/><br/>
       <section className='invest_centerb'>
        <div className='res_row'>
          <div className='res_col'>
               <h2>Fee Structure for Investors</h2>
          </div>
          <div className='res_col'>
            <div className='ress'>
            <a  href = {f1} target = "_blank">Download &nbsp;<i class="ri-download-fill"></i></a>
            </div>
         </div>
        </div>
        <br/><br/>

        <div className='res_row'>
          <div className='res_col'>
               <h2>Fee Structure for Advisor</h2>
          </div>
          <div className='res_col'>
            <div className='ress'>
            <a  href = {f2} target = "_blank">Download &nbsp;<i class="ri-download-fill"></i></a>
            </div>
         </div>
        </div>
        <br/><br/>


        <div className='res_row'>
          <div className='res_col'>
               <h2>Fund Fact Sheet</h2>
          </div>
          <div className='res_col'>
            <div className='ress'>
            <a  href = {f3} target = "_blank">Download &nbsp;<i class="ri-download-fill"></i></a>
            </div>
         </div>
        </div>
<br/><br/>

        <div className='res_row'>
          <div className='res_col'>
               <h2>Case Study</h2>
          </div>
          <div className='res_col'>
            <div className='ress'>
            <a  href = {f4} target = "_blank">Download &nbsp;<i class="ri-download-fill"></i></a>
            </div>
         </div>
        </div>
        <br/><br/>

        <div className='res_row'>
          <div className='res_col'>
               <h2>33 Percent Fund Pioneering Strategy</h2>
          </div>
          <div className='res_col'>
            <div className='ress'>
            <a  href = {f5} target = "_blank">Download &nbsp;<i class="ri-download-fill"></i></a>
            </div>
         </div>
        </div>
<br/><br/>

 
       </section>

    </div>
  )
}

export default Resources;