import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/faq.css'

import AOS from 'aos';
import 'aos/dist/aos.css';


const Faq = () => {
    useEffect(()=>{
        AOS.init();
       
      })
    
  return (
    <div>
         <Helmet>
       <title>33 Percent Funding FAQ: Your Comprehensive Guide to Funding Queries - 33 Percent FUND</title>
      <meta name="description" content="Discover answers to all your funding questions with our comprehensive FAQ guide! Get insights on 33% funding and more."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
       
        
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">FAQ</h1>
         </section>

       <section className='faq_center'>

        <div className='faq__top'>
          <h1>Invest with Your Self-Directed IRA at 33% Fund</h1>
         <h2>Step 1: Open a Self-Directed IRA</h2>
         <p>- Choose a custodian that allows investments in hedge funds. Recommended custodians include:</p>
         <p>- EasyIRA Solutions: Known for its user-friendly process and excellent customer support.</p>
         <p>- PrimeTrust IRA Services: Offers an efficient online setup and a user-friendly platform.</p>
         <p>- Open your self-directed IRA account with your chosen custodian.</p>
         <h2>Step 2: Fund Your IRA</h2>
         <p>- You can fund your account either by rolling over an existing retirement account or by making a direct contribution.</p>
         <h2>Step 3: Perform Your Due Diligence</h2>
         <p>- Research 33% Fund to understand our investment strategies, historical performance, and fee structure.</p>
         <h2>Step 4: Direct Your IRA to Invest in 33% Fund</h2>
         <p>- Inform your IRA custodian of your decision to invest in 33% Fund.</p>
         <p>- Complete any necessary authorization forms provided by your custodian and/or by 33% Fund.</p>
         <h2>Step 5: Confirmation and Monitoring</h2>
         <p>- Once your IRA custodian processes your investment, you will receive confirmation.</p>
         <p>- Monitor your investment through statements from your custodian or directly from us at 33% Fund.</p>
         <h2>Need Assistance?</h2>
         <p>- If you have any questions or need assistance, please contact our support team at <span style={{fontWeight:"bold"}}>info@33percentfund.com </span> or call us at 346 738 0444.</p>
         </div>
        <div  className='des' >
            <h2 data-aos="fade-right">What is 33% Fund and what do you offer?</h2>
            <br />
            <p data-aos="fade-right">33% Fund is a dividend-oriented hedge fund that focuses on generating attractive investment returns through diversified strategies. We offer a range of investment approaches designed to optimize returns while managing risk.</p>
        </div>

  <br />
        <details>
  <summary data-aos="fade-right">How can I contact 33% Fund for inquiries?</summary>
  <div>
  You can reach out to us via email at contact@33percentfund.com or visit our office at 123 Investment Avenue, Houston.
  </div>
</details>
<br />
<details>
  <summary data-aos="fade-right">Where is 33% Fund located?</summary>
  <div>
  Our office is located at 123 Investment Avenue, Houston. You can also contact us through our online platforms.
  </div>
</details>

<br />
<details>
  <summary data-aos="fade-right">What are the benefits of investing with 33% Fund?</summary>
  <div>
  Investing with 33% Fund provides you access to expert-driven strategies, diversified portfolios, and the potential for attractive returns with controlled volatility.
  </div>
</details>

<br />
<details>
  <summary data-aos="fade-right">How can I stay updated on your investment strategies and news?</summary>
  <div>
  Stay informed by following us on LinkedIn, Facebook, and Twitter. You can also subscribe to our newsletters for regular updates.
  </div>
</details>

<br />
<details>
  <summary data-aos="fade-right">Is 33% Fund open to both individual and institutional investors?</summary>
  <div>
  Yes, we welcome both individual and institutional investors to join us in their pursuit of financial success.
  </div>
</details>

<br />
<details>
  <summary data-aos="fade-right">What makes 33% Fund unique in the investment landscape?</summary>
  <div>
  Our multi-strategy approach, seasoned team, and commitment to financial literacy set us apart in the investment landscape.
  </div>
</details>


<br />
<details>
  <summary data-aos="fade-right">Can I visit your office for an in-person consultation?</summary>
  <div>
  Absolutely, you're welcome to visit our office for a consultation. Contact us to schedule an appointment.
  </div>
</details>
       </section>

      <section>
      </section>

    </div>
  )
}

export default Faq;