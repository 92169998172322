import React, { useEffect } from 'react'

import {Helmet}from"react-helmet";
import '../components/Styles/ebook.css'

import f1 from './Full Book PDF.pdf';
import AOS from 'aos';
import 'aos/dist/aos.css';
import book from '../assets/images/book.png'

const Ebook2 = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
                <Helmet>
       <title>Download Your Exclusive eBook | 33 Percent Fund Insights</title>
      <meta name="description" content="Unlock invaluable insights with our exclusive eBook! Download now for a 33 percent deeper understanding of fund strategies. Your key to financial success awaits"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
    
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">eBook</h1>
         </section>
         <br /><br />
         <section className='esecbook2'>
              <h2>Investivus for the rest of us <br />By</h2>
              <h2>Mohammad Ali Suleiman</h2>
                    <img src={book} alt="" />
                    <br />
                    <button>  <a  href = {f1} target = "_blank">Download &nbsp;<i class="ri-download-fill"></i></a>
     </button>
                  
              
         </section>
<br /><br />

<br />
 <section className='esec2'>
<div className='esecc'>
<h2>Get Started Today</h2>
<p>Ready to embark on your investment journey with us? Contact our investor relations team to get started or schedule a one-on-one consultation with one of our portfolio managers.</p>
</div>
 </section>
    </div>
  )
}

export default Ebook2;