import BLOG1 from './images/blg1.png';
import BLOG2 from '../pages/feature.png';
import BLOG3 from '../pages/bg3.png';
import BLOG4 from '../components/Styles/Blog4.png';
import BLOG5 from '../pages/Artboard.jpg';
import BLOG6 from '../pages/blog6.png';
import BLOG8 from '../pages/bl8.png';
import BLOG9 from '../pages/bg9.png';
import BLOG10 from '../pages/bg10.png';
import BLOG12 from '../pages/bg12-min.png';
import BLOG11 from '../pages/blog11-min.png';
import BLOG13 from '../pages/bg13-min.png';
import BLOG15 from '../pages/blg15-min.png';
import BLOG16 from '../pages/Blg16.png';
export const BLOG_LIST = [
  {
    image: BLOG16,
    title: 'The Importance of Value Investing',
   Link: 'The-Importance-of-Value-Investing'
  }, 
  {
    image: BLOG15,
    title: "A Key Strategy in Today's Shariah-Compliant Market",
   Link: "A-Key-Strategy-in-Today's-Shariah-Compliant Market"
  },

  {
    image: BLOG6,
    title: 'Navigatin the Winds of Change',
   Link: "Navigatin-the-Winds-of-Change"
  },
 
  {
    image: BLOG13,
    title: 'Navigating Market Volatility',
   Link: "Navigating-Market-Volatility"
  },
  {
    image: BLOG12,
    title: 'Investing in Tomorrow',
   Link: "Investing-in-Tomorrow"
  },
  
  {
    image: BLOG11,
    title: 'Mastering Market Volatility',
   Link: "Mastering-Market-Volatility"
  },
  
  {
    image: BLOG10,
    title: 'Bitcoin Soars to New Heights',
   Link: "Bitcoin-Soars-to-New-Heights"
  },
  {
    image: BLOG9,
    title: 'Navigating Market Dynamics',
   Link: "Navigating-Market-Dynamics"
  },
  {
    image: BLOG8,
    title: 'Navigating Prosperity',
   Link: "Navigating-Prosperity"
  },

  {
    image: BLOG3,
    title: 'Stock Market Dynamics: Navigating Ebb & Flow with Positivity',
   Link: "today's-stock-market-analysis"
  },
  {
    image: BLOG6,
    title: 'January 2024 high tech success insights',
   Link: 'January-2024-high-tech-success-insights'
  },    {
    image: BLOG5,
    title: 'A Strategic Shift Towards Accumulation Mode',
   Link: 'A-Strategic-Shift-Towards-Accumulation-Mode'
  },
  {
    image: BLOG4,
    title: '  Long term Growth Strategies',
   Link: '  Long-term-Growth-Strategies'
  },


  ];
  
