import React, { useEffect } from 'react'
import '../components/Styles/page.css'
import mobile1 from '../assets/images/mobile1.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Page2 = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
        
        <section className='about__us' >
          <h1 data-aos="zoom-in">Unlock Your Financial Future with 33 Percent Fund</h1>
          <p data-aos="zoom-in">Empowering Your Investments with Strategic Insights and Expertise</p>
         </section>
         <br /><br />
        
         <section className='page_main'>
           <div className='page_row'>
            <div className='col_page'>
                <h2 data-aos="fade-right">Book a Zoom Call with Mohammad Ali, Our Expert Fund Manager</h2>
            <p data-aos="fade-right">Ready to supercharge your investments? Schedule a one-on-one Zoom call with Mohammad Ali, our seasoned fund manager.</p>
             <h2 data-aos="fade-right">Why Connect with Mohammad Ali? </h2>
             <ul>
                <li data-aos="fade-right">Proven Expertise: Mohammad Ali brings a wealth of industry knowledge and a track record of successful investments.</li>
                <li data-aos="fade-right">Proven Expertise: Mohammad Ali brings a wealth of industry knowledge and a track record of successful investments.</li>
               <li data-aos="fade-right">Risk Management: Mohammad Ali will discuss risk mitigation strategies and help you protect your investments.</li>
              <li data-aos="fade-right">Portfolio Optimization: Whether you're just starting or have an existing portfolio, he can optimize it for better returns.</li>
             </ul>
            </div>
            <div className='col_page'>
                <img data-aos="fade-left" src={mobile1} alt="" />

</div> 
           </div>
         </section>
<br />
<section className='page_main'>
<div className='pg_r2'>
<div className='clpgr' data-aos="zoom-in">
<h2>Provide Your Details</h2>
<p>Share your name, email, and preferred contact time below.</p>
</div>
<div className='clpgr' data-aos="zoom-in">
<h2>Confirmation</h2>
<p>We'll confirm your Zoom consultation and send you a calendar invite.</p>
</div>
<div className='clpgr' data-aos="zoom-in">
<h2>Prepare for Success</h2>
<p>Gather any financial questions or information you'd like to discuss with Mohammad Ali.</p>
</div>
</div>
</section>
<br /><br />
         <section className='page_last'>
        <div className='lst_text'>
<p data-aos="zoom-in">Get your questions answered on a Zoom call with Mohammad Ali, the fund manager. He's dedicated to helping you make smart financial decisions.</p>
        <button data-aos="zoom-in">Join the Fund Now</button>
        </div>
         </section>
    </div>
  )
}

export default Page2;