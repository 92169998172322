import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/about.css'
import abi from '../assets/images/ab1.png';
import ab2 from '../assets/images/ab2.png';
import ab3 from '../assets/images/ab3.png';
import ab4 from '../assets/images/ab4.png';
import dm1 from '../assets/images/xyy.jpg'
import dm2 from '../assets/images/dm2.png'
import dm3 from '../assets/images/dm3.png'
import AOS from 'aos';
import 'aos/dist/aos.css';


const About = () => {
    useEffect(()=>{
        AOS.init();
       
      })
    
  return (
    <div>
                  <Helmet>
      
      <title>Learn About Percent Fund - Our Mission, Team, and Impact - 33 Percent Fund</title>
      <meta name="description" content="Discover the mission and vision behind Percent Fund. Learn about our commitment to financial empowerment and the innovative solutions driving change."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">About Us</h1>
         </section>

       <section className='about_center'>
      <div className='row'>
        <div className='col' >
            <h2 data-aos="fade-right">Overview</h2>
            <br />
            <p data-aos="fade-right">Our mission at 33% Fund is centered on empowering you to navigate the intricate landscape of investments with confidence and clarity. We recognize that financial success is not just about accumulating wealth; it's about fulfilling dreams, securing futures, and embracing opportunities. To us, your financial aspirations are the heart of our endeavor.</p>
        </div>
        <div className='col'>
          <img src={abi} alt=""  data-aos="fade-left"/>
        </div>
      </div>
      <div className='row'>
        <div className='col' >
        <p data-aos="fade-right"><span id='sp1'>Our Story: </span>Shaping the Future of Investments Rooted in a deep passion for growth and guided by a team of seasoned experts, 33% Fund has carved a trailblazing path by consistently delivering remarkable returns to our valued investors. Our journey is a testament to innovation, dedication, and the pursuit of excellence.</p>
        </div>
        <div className='col'>
          <img src={ab2} alt=""  data-aos="fade-left"/>
        </div>
      </div>
      <div className='row'>
        <div className='col' >
        <p data-aos="fade-right"><span id='sp1'>Our Values:</span>Integrity | Innovation | Excellence These aren't just words to us; they define our very essence. Upholding the highest standards of integrity, continuously innovating to align with the dynamic market landscape, and relentlessly striving for excellence in every facet of our operations are the cornerstones of our philosophy.
</p></div>
        <div className='col'>
          <img src={ab3} alt=""  data-aos="fade-left"/>
        </div>
      </div>
      <div className='row'>
        <div className='col' >
        <p data-aos="fade-right"><span id='sp1'>Nurturing Your Investments for Long-Term Growth:</span>Explore our dividend-oriented investment approach, a unique blend of steady income and the potential for significant growth. Our robust multi-strategy methodology ensures your investments remain diversified and optimally positioned for sustainable prosperity.</p></div>
        <div className='col'>
          <img src={ab3} alt=""  data-aos="fade-left"/>
        </div>
      </div>
      </section>
           


          

<br /><br />
 <section className='banner' data-aos="zoom-in">
<h2>Discover Our Investment Strategies</h2>
<button ><a href="investment">Learn More</a></button>
 </section>
 <br /><br />
       <section className='aboutlast'>
        <h2 data-aos="fade-right">Leadership Team:</h2>
        <div className='row2'>
        <div className='coll'>
            <img data-aos="zoom-in" src={dm1} alt="" />
            <h2 data-aos="fade-right">Mohammad Ali </h2>
            <p data-aos="fade-right">Founding Partner</p>
            <p data-aos="fade-right">Introducing our visionary founder, Muhammad Ali. With decades of experience and an innate understanding of financial markets, Muhammad has steered the success of 33% Fund. Beyond his financial prowess, he's a kickboxing enthusiast with a dedication to educating about financial literacy.</p>
        </div>
        <div className='coll'>
        <img data-aos="zoom-in" src={dm2} alt="" />
        <h2 data-aos="fade-right">Veronica Kanaan</h2>
        <p data-aos="fade-right">VP Administration</p>
        <p data-aos="fade-right">Meet Vera Kanaan, our Vice President of Administration. With over a decade of finance experience, her organizational finesse ensures the seamless operation of the fund. When she's not enhancing processes, she enjoys reading, yoga, and exploring culinary delights.</p>
        </div>
        <div className='coll'>
        <img data-aos="zoom-in" src={dm3} alt="" />
            <h2 data-aos="fade-right">Shahzad Ashraf</h2>
            <p data-aos="fade-right">Marketing Director</p>
            <p data-aos="fade-right">Introducing Shahzad Ashraf, our Marketing Director. With a wealth of marketing experience, Shahzad excels in building strong relationships and crafting effective outreach initiatives. When he's not devising marketing strategies, he enjoys hiking, traveling, and volunteering</p>
        </div>
        </div>
       </section>

    </div>
  )
}

export default About;