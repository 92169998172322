import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/careers.css'

import AOS from 'aos';
import 'aos/dist/aos.css';


const Careers = () => {
  useEffect(()=>{
    AOS.init();
   
  })

  return (
    <div>
         <Helmet>
       <title>Explore Career Opportunities At 33 Percent Fund - 33 Percent Fund</title>
      <meta name="description" content="Unlock Exciting Career Prospects with 33% Fund! Discover Your Path to Success and Growth with Our Innovative Team. Join Us Today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      </Helmet>
       
        <section className='about__us_main' >
          <h1 data-aos="zoom-in">Careers</h1>
         </section>
         <br /><br />
         <section className='career'>
         <div className='row_car'>
          <div className='col_car1'>
            <h2 data-aos="fade-right">A Dynamic Journey Awaits </h2>
          </div>
          <div data-aos="fade-left" className='col_car2'>
            <p>Are you ready for an exciting career combining finance and innovation? At 33% Fund, we're seeking driven professionals who aspire to achieve exceptional investment returns and drive impact within the financial sector.</p>
          </div>

         </div>
         <br />
         <div className='row_car'>
          <div className='col_car1'>
            <h2 data-aos="fade-right">Our Work Environment </h2>
          </div>
          <div className='col_car2' data-aos="fade-left">
            <p>Rooted in a deep passion for growth and guided by a team of seasoned experts, 33% Fund has carved a trailblazing path by consistently delivering remarkable returns to our valued investors. Our journey is a testament to innovation, dedication, and the pursuit of excellence.</p>
          </div>

         </div>
  <br /><br />

         <div className='row_car'>
          <div className='col_car1' data-aos="fade-right">
            <h2>Application Process</h2>
          </div>
          <div className='col_car2' data-aos="fade-left">
            <p>Explore our dividend-oriented investment approach, a unique blend of steady income and the potential for significant growth. Our robust multi-strategy methodology ensures your investments remain diversified and optimally positioned for sustainable prosperity.</p>
          </div>

         </div>
         </section>

 
    </div>
  )
}

export default Careers;